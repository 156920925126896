import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ApexBarLineDetails, ProgressColor } from '../../../../../helpers';
import { Capitalize } from "../../../../../hooks";

import {
  Col,
  Container,
  Row,
  Card,
  Media,
  CardBody
} from "reactstrap";

import Breadcrumbs from "../../../../../components/common/Breadcrumb";

function CommerceTrackingDetailsCommercial(props) {
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [internal, setInternal] = useState({});
  const [lastUpdateDate, setLastUpdateDate]= useState(undefined);
  const [lang, setLang] = useState(undefined);

  useEffect(() => {
    setLang(localStorage.getItem("I18N_LANGUAGE"));
    setInternal(location['state']['current_internal']);
    setLastUpdateDate(location['state']['last_update_date']);
    console.log(location['state'])
    if (document.getElementById('apexBarLineDetails') && document.getElementById('apexBarLineDetails').innerHTML === "") {
      ApexBarLineDetails(location['state']['current_internal']);
    }

    setLoader(false);
  }, [location]);

  function renderFamilyProductsCA() {
    if (Object.keys(internal).length) {
      const activitiesFilter = internal['items_familly'][0].sort((a, b) => (b.item_family < a.item_family) ? 1 : -1 ).reduce(function (r, a) {
        r[a.item_family] = r[a.item_family] || [];
        r[a.item_family].push(a);

        return r;
      }, []);

      let caData = [];

      activitiesFilter.map((af) => {
        let sumfing = af.reduce((a, b) => {
          return {
            'item_family': a['item_family'],
            'current_revenue': a['current_revenue'] + b['current_revenue'],
          }
        })

        return caData.push(sumfing);
      })

      var objCaData = internal['objectif_items_familly'];

      const data = [].concat(caData).map((details, index) => {
        var caObjective = 0;

        [].concat(objCaData).map((obj) => {
          if (obj['family'] === details.item_family) {
            switch(new Date(Date.now()).getMonth()) {
              case 1:
                caObjective = obj.ca_jan;
                break;
              case 2:
                caObjective = obj.ca_feb;
                break;
              case 3:
                caObjective = obj.ca_mar;
                break;
              case 4:
                caObjective = obj.ca_apr;
                break;
              case 5:
                caObjective = obj.ca_may;
                break;
              case 6:
                caObjective = obj.ca_jun;
                break;
              case 7:
                caObjective = obj.ca_jul;
                break;
              case 8:
                caObjective = obj.ca_aug;
                break;
              case 9:
                caObjective = obj.ca_sep;
                break;
              case 10:
                caObjective = obj.ca_oct;
                break;
              case 11:
                caObjective = obj.ca_nov;
                break;
              case 12:
                caObjective = obj.ca_dec;
                break;
            }
          }

          return null;
        });

        const percent = Math.round((details.current_revenue * 100) / caObjective);

        const family_name = {
          "1": 'VBS',
          "2": 'TBS',
          "4": 'KTS',
          "5": 'BSS',
          "6": 'LFS',
          "7": 'EGS',
          "8": 'UFS',
          "16": 'LFS REHAU',
          "17": 'EGS REHAU',
          "90": 'EPOXY'
        }

        if (details.item_family !== 0) {
          return (
            <li className="list-group-item" key={index}>
              <div className="py-2" >
                <h5 className="font-size-14">
                  {props.t("Family")} : {details.item_family} {family_name[details.item_family.toString()]}
                  <span className="float-end">
                    {percent} %
                  </span>
                </h5>

                <div className="progress animated-progess progress-sm">
                  <div
                    className={"progress-bar bg-" + ProgressColor(percent)}
                    role="progressbar"
                    style={{ width: percent + "%" }}
                    aria-valuenow={percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                  </div>
                </div>
              </div>

              <span>
                {Math.round(details.current_revenue).toLocaleString()} € / {caObjective} €
              </span>
            </li>
          );
        }
        return null;
      });

      return data;
    }
  }

  function renderFamilyProductsVM() {
    if (Object.keys(internal).length) {
      const activitiesFilter = internal['items_familly'][0].sort((a, b) => (b.item_family < a.item_family) ? 1 : -1 ).reduce(function (r, a) {
        r[a.item_family] = r[a.item_family] || [];
        r[a.item_family].push(a);

        return r;
      }, []);

      let vmData = [];

      activitiesFilter.map((af) => {
        let sumfing = af.reduce((a, b) => {
          return {
            'item_family': a['item_family'],
            'current_vm': a['current_vm'] + b['current_vm']
          }
        })

        return vmData.push(sumfing);
      })

      const objVmData = internal['objectif_items_familly'];

      const data = [].concat(vmData).map((details, index) => {
        var vmObjective = 0;

        [].concat(objVmData).map((obj) => {
          if (obj['family'] === details.item_family) {
            switch(new Date(Date.now()).getMonth()) {
              case 1:
                vmObjective = obj.vm_jan;
                break;
              case 2:
                vmObjective = obj.vm_feb;
                break;
              case 3:
                vmObjective = obj.vm_mar;
                break;
              case 4:
                vmObjective = obj.vm_apr;
                break;
              case 5:
                vmObjective = obj.vm_may;
                break;
              case 6:
                vmObjective = obj.vm_jun;
                break;
              case 7:
                vmObjective = obj.vm_jul;
                break;
              case 8:
                vmObjective = obj.vm_aug;
                break;
              case 9:
                vmObjective = obj.vm_sep;
                break;
              case 10:
                vmObjective = obj.vm_oct;
                break;
              case 11:
                vmObjective = obj.vm_nov;
                break;
              case 12:
                vmObjective = obj.vm_dec;
                break;
            }
          }

          return null;
        });

        const percent = Math.round((details.current_vm * 100) / vmObjective);

        const family_name = {
          "1": 'VBS',
          "2": 'TBS',
          "4": 'KTS',
          "5": 'BSS',
          "6": 'LFS',
          "7": 'EGS',
          "8": 'UFS',
          "16": 'LFS REHAU',
          "17": 'EGS REHAU',
          "90": 'EPOXY'
        }

        if (details.item_family !== 0) {
          return (
            <li className="list-group-item" key={index}>
              <div className="py-2" >
                <h5 className="font-size-14">
                  {props.t("Family")} : {details.item_family} {family_name[details.item_family.toString()]}
                  <span className="float-end">
                    {percent}%
                  </span>
                </h5>

                <div className="progress animated-progess progress-sm">
                  <div
                    className={"progress-bar bg-" + ProgressColor(percent)}
                    role="progressbar"
                    style={{ width: percent + "%" }}
                    aria-valuenow={percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              <span>
                {Math.round(details.current_vm).toLocaleString()} € / {vmObjective} €
              </span>
            </li>
          );
        }

        return null;
      });

      return data;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Sales rep team")}
            breadcrumbItem={
              internal['first_name']
              + ' ' +
              internal['last_name']
            } 
          />
        
          <Row>
            {/* card user */}
            <Col xl={8}>
              <Row>
                <Col lg={4}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">
                            {props.t("Sales revenue")}
                          </p>
 
                          <h5 className="mb-0">
                            {internal['activity_ca_cumule'] ? 
                              Math.round(internal['activity_ca_cumule']).toLocaleString() 
                              : null
                            } €
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bx-basket"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">
                            {props.t("Net margin")}
                          </p>

                          <h5 className="mb-0">
                            {
                              internal['activity_vm_cumule'] ?
                                Math.round(internal['activity_vm_cumule']).toLocaleString()
                              : null
                            } €
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bx-money"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">{props.t("Last updated at")}</p>
                          <h5 className="mb-0">{lastUpdateDate !== undefined ? new Date(lastUpdateDate).toLocaleString(lang, { year: 'numeric', month: 'numeric', day: 'numeric' }) : '-'}</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bx-alarm"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <h5 className="card-title me-2">
                      {props.t("Month by month sales activity")}
                    </h5>
                  </div>

                  <div id="apexBarLineDetails" />
                </CardBody>
              </Card>
            </Col>
  
            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="media"> 
                    <div className="media-body">
                      <Media>
                        <div className="me-3">
                          <div className="avatar-sm rounded-circle img-thumbnail">
                            <span className="avatar-title rounded-circle">
                              {internal['first_name'] ? Capitalize(internal['first_name']) : null}.
                              {internal['last_name'] ? Capitalize(internal['last_name']) : null}.
                            </span>
                          </div>
                        </div>

                        <div className="media-body">
                          <div className="text-muted">
                            <h5 className="mb-1">
                              {internal['first_name'] + ' ' + internal['last_name']}
                            </h5>

                            <p className="mb-0">
                              {props.t("Sales rep")}
                            </p>
                          </div>
                        </div>
                      </Media>

                      <hr />

                      <Row>
                        <Col xl={12}>
                          <div>
                            <p className="text-muted text-roundate mb-2">
                              {props.t("Counties")}
                            </p>

                            <h5 className="mb-0">
                              {internal['department'] ? 
                                internal['department'].replace(/,/g, ' - ')
                                : null
                              }
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <h5 className="card-title mb-3 me-2">
                      {props.t("Objectives completion at date")} (%)
                    </h5>
                  </div>

                  <div className="d-flex flex-wrap">
                    <div>
                      <p className="text-muted mb-1">
                        {props.t("Sales revenue")}
                      </p>

                      <h4 className="mb-3">
                        {internal['activity_ca_cumule'] ? 
                          Math.round(internal['activity_ca_cumule']).toLocaleString() : null
                        } € 
                        <span style={{fontSize: 14, color: 'gray'}}> 
                          / { internal['activity_ca_cumule'] ? 
                            Math.round(internal['objectif_ca_cumule']).toLocaleString() : null 
                            } €
                        </span>
                      </h4>

                      <p className="text-success mt-0">
                        <span className={"text-" + ProgressColor(Math.round(
                            (internal['activity_ca_cumule'] * 100) / 
                              internal['objectif_ca_cumule']))}>
                          { Math.round(
                            (internal['activity_ca_cumule'] * 100) / 
                              internal['objectif_ca_cumule'])
                          } %
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap">
                    <div>
                      <p className="text-muted mb-1">
                        {props.t("Net margin")}
                      </p>

                      <h4 className="mb-3">
                        {internal['activity_vm_cumule'] ? 
                          Math.round(internal['activity_vm_cumule']).toLocaleString()
                        : null} €
                        <span style={{fontSize: 14, color: 'gray'}}> 
                          / {internal['activity_vm_cumule'] ? 
                            Math.round(internal['objectif_vm_cumule']).toLocaleString()
                          : null} €
                        </span>
                      </h4>

                      <p className="text-success mt-0">
                        <span className={"text-" + ProgressColor(Math.round(
                            (internal['activity_vm_cumule'] * 100) / 
                              internal['objectif_vm_cumule']))}>
                          { Math.round(
                            (internal['activity_vm_cumule'] * 100) / 
                              internal['objectif_vm_cumule'])
                          } %
                        </span>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-3">
                        {props.t("% objectives completions of")} <span className="text-primary">{props.t("Sales revenue")}</span> {props.t("cumulated by item families")}
                        {(lastUpdateDate !== undefined) ? props.t(" at end of ") + new Date(lastUpdateDate).toLocaleString(lang, { month: 'long', year: 'numeric' }) : "" }
                      </h5>
                    </div>
                  </div>

                  <div>
                    <ul className="list-group list-group-flush">
                      {renderFamilyProductsCA()}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-3">
                        {props.t("% objectives completions of")} <span className="text-primary">{props.t("Net margin")}</span> {props.t("cumulated by item families")}
                        {lastUpdateDate !== undefined ? props.t(" at end of ") + new Date(lastUpdateDate).toLocaleString(lang, { month: 'long', year: 'numeric' }) : ""}
                      </h5>
                    </div>
                  </div>

                  <div>
                    <ul className="list-group list-group-flush">
                      {renderFamilyProductsVM()}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {loader ? 
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </Col>
            </Row> 
          : null}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(CommerceTrackingDetailsCommercial);
