import React, { useState } from "react";
import { axiosClient } from "../../../../../helpers";
import { Alerts } from "../../../../../helpers";
import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../../../components/common/Breadcrumb";
import { property } from "lodash";

const UsersCreate = (props) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // ALERT 
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertType, setDisplayAlertType] = useState('');
  const [alertText, setAlertText] = useState('');

  function submitForm(e) {
    e.preventDefault();
    setLoading(true);

    axiosClient.post('/ie/internals',
      {
        email: email,
        first_name: firstname,
        last_name: lastname
      })
      .then((response) => {
        setLoading(false);
        setDisplayAlertType('success');
        setAlertText(props.t('Sales rep account created ! The sales rep will receive his password directly by email.'))
        setDisplayAlert(true);
      })
      .catch((error) => {
        setDisplayAlertType('error');
        setAlertText(props.t('An error occured, an account may already exist.'))
        setDisplayAlert(true);
        setLoading(false);
      })
  }

  function renderAlert() {
    if (displayAlert) {
      return Alerts(displayAlertType, alertText);
    }
  }

  function renderButton() {
    const spin = <div className="spinner-border text-primary mx-auto" role="status" />
    const btn = <button className="btn btn-success btn-block waves-effect waves-light"
                  type="submit"
                >
                  {props.t("Create the sales rep")}
                </button>

  return loading ? spin : btn;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Sales rep team")} breadcrumbItem={props.t("New sales rep")} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t('Create a new sales rep')}
                  </CardTitle>

                  <Form onSubmit={submitForm}>
                    {renderAlert()}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("First name")}
                      </Label>

                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          placeholder={props.t('Sales rep first name...')}
                          value={firstname}
                          required
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Last name')}
                      </Label>

                      <Col lg="10">
                        <Input
                          className="form-control"
                          id="projectdesc"
                          placeholder={props.t("Sales rep last name...")}
                          value={lastname}
                          required
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Email address")}
                      </label>

                      <Col lg="10">
                        <Input
                          id="projectbudget"
                          name="projectbudget"
                          type="email"
                          placeholder={props.t("Sales rep email...")}
                          className="form-control"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        {props.t("Region")}
                      </label>

                      <Col lg="10">
                        <Input
                          id="projectbudget"
                          name="projectbudget"
                          type="text"
                          placeholder={props.t("Sales rep region...")}
                          className="form-control"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        {props.t('Counties')}
                      </label>

                      <Col lg="10">
                        <Input
                          id="projectbudget"
                          name="projectbudget"
                          type="text"
                          placeholder={props.t("Sales rep counties...")}
                          className="form-control"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <Row className="justify-content-end">
                      <Col lg="10">
                        {renderButton()}
                      </Col>
                    </Row>
                  </Form> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(UsersCreate);
