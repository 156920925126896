import ApexCharts from "apexcharts";

export default function ApexBarPositiveNegative(internals) {
  const filterInternal = [].concat(internals).filter((internal) => internal.department !== null) 
  
  const team = filterInternal.map((internal) => {
    return (internal.first_name + ' ' + internal.last_name)
  })

  const current_ca_objectives = filterInternal.map((internal) => {
      const ca = internal.activity_ca_cumule
      const obj = internal.objectif_ca_cumule
      return Math.round(((ca * 100) / obj))
  })

  const current_vm_objectives = filterInternal.map((internal) => {
      const vm = internal.activity_vm_cumule
      const obj = internal.objectif_vm_cumule
      return -Math.round(((vm * 100) / obj))
  })

  var options = {
    series: [
      {
        name: "Chiffre d'affaires",
        data: current_ca_objectives
      },
      {
        name: 'Volume de marge',
        data: current_vm_objectives
      }
    ],
    chart: {
      type: 'bar',
      height: 800,
      stacked: true
    },
    colors: ['#008FFB', '#FF4560'],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    yaxis: {
      min: -100,
      max: 100,
      title: {
        // text: 'Age',
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val
        }
      },
      y: {
        formatter: function (val) {
          return Math.abs(val) + "%"
        }
      }
    },
    title: {
      text: "% d'atteinte des objectifs au cumul par TCI"
    },
    xaxis: {
      categories: team,
      title: {
        text: 'Pourcentage de réalisation'
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val)) + " %"
        }
      }
    }
  };

  var chart = new ApexCharts(document.getElementById('apexBarPositiveNegative'), options)
  return chart.render()
}
