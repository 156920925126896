import {
  Col,
  Card,
  CardBody,
  Media
} from "reactstrap"

export default function reports(datas) {
  const renderReport = datas.map((report, key) => {

    return (
      <Col md="4" key={"_col_" + key}>
        <Card className="mini-stats-wid">
          <CardBody>
            <Media>
              <Media body>
                <p className="text-muted fw-medium">
                  {report.title}
                </p>
                {
                  report.name? <h5 className="mb-0">{report.name}</h5> : null
                
                }
                {
                  report.date? <p className="mb-0">{(new Date(report.date)).toLocaleDateString()}</p> : null
                
                }
              </Media>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className={"avatar-title rounded-circle "
                  + (report.danger? 'bg-danger' : 'bg-primary')}>
                  <i
                    className={
                      "bx " + report.iconClass + " font-size-24"
                    }
                  ></i>
                </span>
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    )
  })
  
  return renderReport;
}