import React, { useState, useEffect } from 'react';
import { axiosClient } from "../../../../helpers";
import { Link } from "react-router-dom";
import { Alerts, ProgressColor } from "../../../../helpers";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../../components/common/Breadcrumb";

import { Container, Table } from "reactstrap";

const Clients = (props) => {
  const [clients, setClients] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const [clientsList, setClientsList] = useState<any>();
  const [date, setDate] = useState(undefined);
  const [clientsGroupsDatas, setClientsGroupsDatas] = useState<any>();
  const [clientsGroupsName, setClientsGroupsName] = useState<any>();
  const [clientsGroupsObjectives, setClientsGroupsObjectives] = useState<any>();
  const [activity, setActivity] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [lang, setLang] = useState(undefined);

  useEffect(() => {
    setLoader(true);

    setLang(localStorage.getItem("I18N_LANGUAGE"));

    // const cacheDate = localStorage.getItem('cacheActivityDataDate');
    // const cacheObjectifClientsGroup = localStorage.getItem('cacheObjectifClientsGroup');
    // const cacheActivityClients = localStorage.getItem('cacheActivityClients');
    // const cacheClients = localStorage.getItem('cacheClients');

    // setDate(JSON.parse(cacheDate));
    // setClientsGroupsObjectives(JSON.parse(cacheObjectifClientsGroup));

    // if (cacheClients && cacheActivityClients) { 
    //   setActivity(JSON.parse(cacheActivityClients));
    //   setClients(JSON.parse(cacheClients));
    //   setLoader(false);
    // };

    axiosClient.get('/ie/clients')
      .then((response) => {
        setActivity(response.data.client_data);
        setClients(response.data.clients);
        setClientsGroupsName(response.data.clients_groups);
        setClientsGroupsObjectives(response.data.clients_groups_objectives);
        setDate(response.data.activity_date);
        setClientsGroupsDatas(response.data.client_group_data);

        // localStorage.setItem("cacheActivityClients", JSON.stringify(response.data.client_data));
        // localStorage.setItem("cacheClients", JSON.stringify(response.data.clients));
        // localStorage.setItem("cacheObjectifClientsGroup", JSON.stringify(response.data.clients_groups_objectives));
        // localStorage.setItem("cacheClientsGroupsName", JSON.stringify(response.data.clients_groups));
        // localStorage.setItem("cacheClientsGroupsDatas", JSON.stringify(response.data.client_group_data));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        Alerts('error', props.t('Unable to fetch clients details...'));
      })
  }, [])

  function switchModal(e) {
    e.preventDefault();
    setModalOpen(!modalOpen);
  }

  function displayProgressbar(percent, text) {
    if (percent === undefined) {
      return (
        <React.Fragment>
          {props.t(text)} : {props.t("Incomplete or Missing Data")}
          <br />
        </React.Fragment>
      );
    } else {
      return (
        <div className="progress mt-2 mb-1">
          <div className={"progress-bar bg-" + ProgressColor(percent)}
            role="progressbar"
            style={{ width: (percent > 33 ? percent : 33) + '%' }}
            aria-valuenow={85}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {props.t(text)} : {percent} %
          </div>
        </div>
      );
    }
  }

  function groupClients() {
    if (clientsGroupsDatas && clientsGroupsObjectives) {
      return [].concat(clientsGroupsObjectives.objectives_per_clients).map((client, index) => {
        const cgDatas = [].concat(clientsGroupsDatas)
          .filter((group) => group.client_group.toString().toLowerCase() === client.client_group.toString().toLowerCase());

        if (cgDatas.length > 0) {
          var dataCa = cgDatas[0].ca_pondere;
          var dataVm = cgDatas[0].vm_pondere;
        }

        let ca = dataCa ? dataCa : undefined;
        let vm = dataVm ? dataVm : undefined;

        const clients_list = clients[Object.keys(clients).find(key => key === client.client_group)];

        return (
          <li key={index}
            className="list-group-item"
          >
            <Link to="#"
              className="item"
              onClick={(e) => [switchModal(e), setClientsList(clients_list)]}
            >
              <div className="in row align-items-center">
                <div className="col-11">
                  <header>
                    {props.t("Grouping")} : {client.client_group}
                  </header>

                  {displayProgressbar(ca, "Sales Revenue")}

                  {displayProgressbar(vm, "Net Margin")}
                </div>

                <div className="col-1">
                  <button type="button"
                    className="btn btn-outline-info"
                  >
                    {props.t("Details")}
                  </button>
                </div>
              </div>
            </Link>
          </li>
        );
      });
    } else {
      return (
        <React.Fragment>
          <h1>{props.t("Missing Datas")}</h1>
        </React.Fragment>
      );
    }
  }

  function renderLoader() {
    if (loader) {
      return (
        <div style={
          { 
            zIndex: 999, 
            height: '100%', 
            width: '100%',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            backgroundColor: '#ffffffeb'
          }}
        >
          <div className="spinner-border text-warning"
            role="status"
          >
          </div>
        </div>
      );
    }
  }

  function renderModal() {
    if (clientsList) {
      const renderData = () => {
        const list = clientsList.map((client, index) => {
          const groupActivity = [].concat(activity)
            .filter((current) => {
              // CHANGE THIS CHIMICHANGAS ?
              if (current.client_reference.toString().length > 8) {
                // REXEL LIKELY
                return current.client_reference.toString().includes(client.reference);
              } else {
                return current.client_reference === Number(client.reference);
              }
            });

          return (
            <React.Fragment key={index}>
              <tr>
                <td className="text-wrap">
                  {(client.sub_name !== undefined && client.sub_name !== null) ? client.name + "-" + client.sub_name : client.name}
                </td>

                <td className="text-wrap">
                  {(client.sub_reference !== undefined && client.sub_reference !== null) ? client.reference + "-" + client.sub_reference : client.reference}
                </td>

                <td className="text-wrap">
                  {client.address}
                </td>

                <td className="text-wrap">
                  {client.zipcode}
                </td>

                <td className="text-wrap">
                  {client.city}
                </td>

                <td className="text-wrap">
                  <a href={"tel:+" + client.phone}>
                    {client.phone}
                  </a>
                </td>

                <td className="text-wrap">
                  <span className="text-success">
                    {groupActivity[0] ? Math.round(groupActivity[0].ca) : 0} €
                  </span>
                </td>

                <td className="text-wrap">
                  <span className="text-success">
                    {groupActivity[0] ? Math.round(groupActivity[0].vm) : 0} €
                  </span>
                </td>
              </tr>
            </React.Fragment>
          );
        });

        return (
          <Table className="project-list-table table-nowrap align-middle table-borderless table-hover">
            <thead>
              <tr>
                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("Client Name")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("Client Id")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("Address")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("Zipcode")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("City")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("Phone Number")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {date !== undefined ? props.t("Cumulated sales revenue at end of ") + new Date(date).toLocaleString(lang, { month: 'long' }) : props.t("Cumulated sales revenue")}
                </th>

                <th scope="col"
                  className="text-wrap"
                >
                  {props.t("Net margin")} {date !== undefined ? props.t("Already realised at end of ") + new Date(date).toLocaleString(lang, { month: 'long' }) : props.t("Already realised")}
                </th>
              </tr>
            </thead>

            <tbody>
              {list}
            </tbody>
          </Table>
        );
      }

      return (
        <div className={"modal fade modalbox " + (modalOpen ? 'show' : null)} 
          role="dialog"
          style={{
            display: 'block',
            zIndex: modalOpen ? 1064 : -1,
            "width": "100%"
          }}
        >
          <div className="modal-dialog modal-fullscreen"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {props.t("Grouping")} : {clientsList[0].client_group}
                </h5>

                <button type="button"
                  className="btn btn-outline-primary"
                  onClick={(e) => switchModal(e)}
                >
                  {props.t("Close")}
                </button>
              </div>

              <div className="modal-body"
                style={{ background: '#EDEDF5' }}
              >
                {renderData()} 
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {/* Modal Details Client & activity */}
      {renderModal()}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Clients sales details")} 
            breadcrumbItem={props.t("CLIENTS SALES DETAILS")}
          />

          {/* render loader */}
          {renderLoader()}

          <div className="listview-title mt-2">
            {props.t("List of clients groupings")}
          </div>

          <ul className="listview image-listview list-group list-group-flush">
            {groupClients()}
          </ul>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Clients);