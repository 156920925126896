import ApexCharts from "apexcharts";
import { filterMonth, ProgressColor } from "../../helpers";

export default function ApexeBarLineHorizontal(activities, objectives) {

  if(activities && objectives){
    const percentCompletion = [].concat(activities).map((familyData, array) => {
      const id = familyData['item_family']
      const objdata = [].concat(objectives.objectives_per_family)
      .filter(family => family['family'] === id)
      .map((objectivesData) => { 
        return [objectivesData[filterMonth()[0]], objectivesData[filterMonth()[1]]];
      })
      if (objdata.length) {
        const vmPercent = Math.round((familyData['current_vm'] * 100) / objdata[0][1]);
        const caPercent = Math.round((familyData['current_revenue'] * 100) / objdata[0][0]);
        return [Math.round(id), caPercent, vmPercent]
      }
      return null;
    })

    const datas = percentCompletion.filter(function(e){return e});
    
    const ids = datas.map(function(x) {
      return 'Famille : '+ x[0];
    });
    const cas = datas.map(function(x) {
      return x[1];
    });
    const vms = datas.map(function(x) {
      return x[2];
    });

    let zipped = cas.map((x, i) => [x, vms[i]]);
    const arrColors = [];
    zipped.map((c) => {
      let color1 = '';
      let color2 = '';
      if (ProgressColor(c[0]) === 'success') {
        color1 = '#4cf197';
      } else 
      if (ProgressColor(c[0]) === 'warning') {
        color1 = '#f1b44c';
      } else 
      if (ProgressColor(c[0]) === 'danger') {
        color1 = '#f46a6a';
      }
      if (ProgressColor(c[1]) === 'success') {
        color2 = '#4cf197';
      } else 
      if (ProgressColor(c[1]) === 'warning') {
        color2 = '#f1b44c';
      } else 
      if (ProgressColor(c[1]) === 'danger') {
        color2 = '#f46a6a';
      }
     return arrColors.push(color1, color2);
    })

    var options = {
      series: [
        {
          name: 'CA',
          data: cas
        },
        {
          name: 'VM',
          data: vms
        }
      ],
      chart: {
        type: 'bar',
        height: 800,
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          distributed: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
        },
        colors: ['#fff'],
        formatter: function (val) {
          return Math.abs(Math.round(val)) + " % "
        }
      },
      colors: ['#008FFB'],
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      title: {
        text: "% d'atteinte des objectifs au cumul par familles de produits"
      },     
      xaxis: {
        categories: ids,
        title: {
          text: 'Pourcentage de réalisation'
        },
        max: 100,
        labels: {
          show: true,
          formatter: function (val) {
            return Math.round(val) + " %"
          }
        }
      } 
    };

    var chart = new ApexCharts(document.getElementById('apexBarHorizontal'), options)
    return chart.render()
  } 
}
