import ApexCharts from "apexcharts";

export default function ApexBarLineDetails(content) {

  const global_benefit = [].concat(content.activity_vm_cumule_par_mois).map((activityData) => {
    return parseInt(activityData);
  })

  const current_revenue = [].concat(content.activity_ca_cumule_par_mois).map((activityData) => {
    return parseInt(activityData);
  })

  // const generated_revenue_year_ago = [].concat(data).map((activityData) => {
  //   return parseInt(activityData.global_revenue_one_year);
  // })


  let seriesText = [];
  let date = [];
  let yAxisText = "";

  switch (localStorage.getItem("I18N_LANGUAGE")) {
    case 'fr':
      date = [
        'Janvier', 'Fevrier', 'Mars', 'Avril',
        'Mai', 'Juin', 'Juillet', 'Août',
        'Septembre', 'Octobre', 'Novembre', 'Decembre'
      ];
      seriesText = ['CA réalisé', 'VM réalisé'];
      yAxisText = 'Montant €';
      break;
    case 'en':
      date = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ];
      seriesText = ['Realised Sales Revenue', 'Realised Net Margin'];
      yAxisText = 'Total €'
      break;
  }

  var options = {
    series: [
      {
        name: seriesText[0],
        type: 'column',
        data: current_revenue
      }, {
        name: seriesText[1],
        type: 'column',
        data: global_benefit
      }
    ],
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
      }
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth'
    },
    plotOptions: {
      bar: { columnWidth: '50%' }
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: date,
    markers: { size: 0 },
    xaxis: {
      type: 'category',
      hideOverlappingLabels: false,
      labels: { show: true }
    },
    yaxis: {
      title: { text: yAxisText },
      min: 0,
      labels: {
        formatter: function(val, index) {
          return Math.round(val).toLocaleString();
        }
      },
      forceNiceScale: true
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return (Math.round(y).toLocaleString() + " €");
          }

          return y;
        }
      }
    }
  };

  var chart = new ApexCharts(document.getElementById('apexBarLineDetails'), options);
  return chart.render();
}
