import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ProgressColor, axiosClient } from "../../../../../helpers";

import {
  Col,
  Container,
  Row,
  Table
} from "reactstrap";

import Breadcrumbs from "../../../../../components/common/Breadcrumb";

function CommerceTracking(props) {
  const [loader, setLoader] = useState(false);
  const [activityRegionsConcat, setActivityRegionsConcat] = useState(undefined);
  const [lastUpdate, setLastUpdate] = useState(undefined);
  const [objectives, setObjectives] = useState<any>();
  const [lang, setLang] = useState(undefined);

  useEffect(() => {
    setLoader(true);
    setLang(localStorage.getItem("I18N_LANGUAGE"));

    const cacheObjectives = localStorage.getItem('cacheObjectives');
    const cacheActivityRegionsConcat = localStorage.getItem('cacheActivityRegionsConcat');
    const cacheLastUpdate = localStorage.getItem('cacheLastUpdate');
    
    if (cacheObjectives) { setObjectives(JSON.parse(cacheObjectives)); }
    if (cacheActivityRegionsConcat) { setActivityRegionsConcat(JSON.parse(cacheActivityRegionsConcat)); }
    if (cacheLastUpdate) { setLastUpdate(JSON.parse(cacheLastUpdate)); }

    setObjectives(JSON.parse(cacheObjectives).objective_ca_since_beginning_of_year_region);
 
    axiosClient.get('/ie/activity_infos')
      .then((response) => {
        setActivityRegionsConcat(response.data.activity_regions_concat);
        localStorage.removeItem('cacheActivityRegionsConcat');
        localStorage.setItem('cacheActivityRegionsConcat', JSON.stringify(response.data.activity_regions_concat));
    })
  }, [setActivityRegionsConcat]);
  
  function renderTableHeader() {
    let date = undefined;

    if (lastUpdate) {
      date = new Date(lastUpdate).toLocaleString(lang, { month: 'long', year: 'numeric' }).toString();
    }

    return (
      <tr>
        <th scope="col">{props.t("Region")}</th>
        <th scope="col">{props.t("Manager")}</th>
        <th scope="col">{date !== undefined ? props.t("Cumulated sales revenue ") + date : props.t("Cumulated sales revenue of actual month")}</th>
        <th scope="col">{date !== undefined ? props.t("Cumulated net margin ") + date : props.t("Cumulated net margin of actual month")}</th>
        <th scope="col" className="text-center">{props.t("Action")}</th>
      </tr>
    );
  }

  // Cumulated sales revenue

  function renderTableContent() {
    if (activityRegionsConcat && objectives) {
      const renderData = [].concat(activityRegionsConcat)
        .map((arc, index) => {
          console.log(arc)
          console.log(objectives)
          const objectivesData = [].concat(objectives)
            .filter(obj => obj[2] === arc.region)[0]

          const caActivity = Math.round((arc.current_revenue / objectivesData[0]) * 100);
          const vmActivity = Math.round((arc.current_vm / objectivesData[1]) * 100);

          return (
            <tr key={index}>
              <td>
                <h5 className="text-truncate font-size-14">
                  {arc.region} 
                </h5>
              </td>

              <td>{arc.chief_first_name} {arc.chief_last_name}</td>

              <td>
                {Math.round(arc.current_revenue).toLocaleString()} €
                <br/>

                <span className={"badge badge-pill bg-" + ProgressColor(caActivity)}>
                  {caActivity} %
                </span>
              </td>

              <td>
                {Math.round(arc.current_vm).toLocaleString()} €
                <br/>

                <span className={"badge badge-pill bg-" + ProgressColor(vmActivity)}>
                  {vmActivity} %
                </span>
              </td>

              <td className="text-center">
                <Link to={
                  { 
                    pathname: '/commerce-details',
                    state: { 
                      chief_name: arc.chief_first_name + ' ' + arc.chief_last_name,
                      chief_id: arc.chief_id
                    }
                  }}
                className="btn btn-outline-info"
                >
                  {props.t("Display details")}
                </Link>
              </td>
            </tr>
          );
        })
      
      return renderData;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Sales rep team")} 
            breadcrumbItem={props.t("LIST OF SALES REPS")} />

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      {renderTableHeader()}
                    </thead>

                    <tbody>
                      {renderTableContent()}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {loader && (activityRegionsConcat === undefined || objectives === undefined ) ? 
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </Col>
            </Row> 
          : null}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(CommerceTracking);
