import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { withTranslation } from "react-i18next";
import { axiosClient } from "../../../../../helpers";

import {
  Col,
  Container,
  Row,
  Table,
  Modal,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../../components/common/Breadcrumb";

function UsersIndex(props: any) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState<any>();
  const [modal, setmodal] = useState(false);

  useEffect(() => {
    setLoader(true);
    axiosClient.get('/ie/internals',
    {
      params: { internal_list: true }
    })
      .then((response) => {
        setUsers(response.data)
        setLoader(false);
      })
  }, [setUsers])
  
  function renderChef(user) {
    var Name = '';

    if (user.position === 4) {
      return Name = 'Samuel P.';
    } else if (user.position === 5) {
      switch(user.chief_id) {
        case 6 : 
          return Name = 'Florian C.';
        case 9 : 
          return Name = 'Nicolas R.';
        case 7 : 
          return Name = 'Xavier Q.';
        case 5 : 
          return Name = 'Stéphan D.';
      }
    } else {
      return Name = '-';
    }

    return Name;
  }

  const position = (data) => {
    if (data === 5) {
      return 'TCI';
    } else if (data === 4) {
      return 'CHEF DE REGION';
    } else if (data === 0) {
      return 'DIRECTEUR';
    }
  }

  function tog() {
    setmodal(!modal);
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Sales team")} breadcrumbItem={props.t("Users list")} />

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">{props.t("Last name / First name")}</th>
                        <th scope="col">{props.t("Sales Rep Id Number")}</th>
                        <th scope="col">{props.t("Type")}</th>
                        <th scope="col">{props.t("Chief")}</th>
                        <th scope="col">{props.t("Email address")}</th>
                        <th scope="col">{props.t("Action")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {map(users, (user, index) => (
                        <tr key={index}>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link to="#"
                                className="text-dark"
                              >
                                {user.first_name}
                              </Link>
                            </h5>

                            <p className="text-muted mb-0">
                              {user.last_name}
                            </p>
                          </td>

                          <td>{user.obo_id}</td>

                          <td>
                            <span className="badge rounded-pill bg-info">
                              {position(user.position)}
                            </span>
                          </td>

                          <td>{renderChef(user)}</td>

                          <td>{user.email}</td>

                          <td>
                            <Link to="#" className="btn btn-outline-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                tog();
                                setUser(user);
                              }}
                            >
                              {props.t("Edit")}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          
          <Modal
            isOpen={modal}
            toggle={() => { tog() }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {user ? user.first_name : '-'} {user ? user.last_name : '-'}
              </h5>

              <button
                type="button"
                onClick={() => { setmodal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Form
                className="form-horizontal"
                onSubmit={null}
              >
                {/* {renderAlert()} */}
                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="email"
                  >
                    {props.t("Last name")}
                  </Label>

                  <Col>
                    <Input
                      id="email"
                      name="email"
                      value={user ? user.last_name : null}
                      className="form-control"
                      type="text"
                      required
                      onChange={(e) => null}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="email"
                  >
                    {props.t("First name")}
                  </Label>

                  <Col>
                    <Input
                      id="email"
                      name="email"
                      value={user ? user.first_name : null}
                      className="form-control"
                      type="text"
                      required
                      onChange={(e) => null}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="email"
                  >
                    {props.t("Email address")}
                  </Label>

                  <Col>
                    <Input
                      id="email"
                      name="email"
                      value={user ? user.email : null}
                      className="form-control"
                      placeholder={props.t('Input your email')}
                      type="text"
                      required
                      onChange={(e) => null}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="dpt"
                  >
                    {props.t("Counties")}
                  </Label>

                  <Col>
                    <Input
                      id="dpt"
                      name="dpt"
                      value={user ? user.department : null}
                      className="form-control"
                      placeholder={props.t("Update counties")}
                      type="text"
                      required
                      onChange={(e) => null}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label htmlFor="dpt">
                    {props.t("Password")}
                  </Label>

                  <Col>
                    <Link to="#" className="btn btn-outline-info">
                      {props.t("Generate a new password")}
                    </Link>
                  </Col>
                </FormGroup>
              </Form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => { tog() }}
                className="btn btn-outline-secondary waves-effect"
                data-dismiss="modal"
              >
                {props.t("Close")}
              </button>

              <button
                type="button"
                className="btn btn-success waves-effect waves-light"
              >
                {props.t('Save changes')}
              </button>
            </div>
          </Modal>    

          {loader ? 
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </Col>
            </Row> 
          : null}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(UsersIndex);
