import * as React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Layout from "./components/layout/layout";
import { IsAuthenticated } from './helpers';

import { 
  Login, 
  Password, 
  Profile, 
  UsersCreate, 
  UsersIndex,
  UploadStocks,
  CommerceTracking,
  CommerceTrackingDetails,
  CommerceTrackingDetailsCommercial,
  ManagerDashboard,
  Statistics,
  Clients
} from "./pages";

const publicRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/password">
          <Password />
        </Route>
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
    </Router>
  )
}

const privateRoutes = (position) => {
  function dispatch() {
    switch (position) {
      case 0 : 
        return (
          <Router>
            <Switch>
              <Layout>
                <Route exact path="/">
                  <ManagerDashboard />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/team">
                  <UsersIndex />
                </Route>
                <Route path="/team/create">
                  <UsersCreate />
                </Route>
                <Route path="/commerce">
                  <CommerceTracking />
                </Route>
                <Route path="/commerce-details/">
                  <CommerceTrackingDetails />
                </Route>
                <Route path="/commercial-details">
                  <CommerceTrackingDetailsCommercial />
                </Route>
                <Route path="/upload-stocks">
                  <UploadStocks />
                </Route>
                <Route path="/statistics">
                  <Statistics />
                </Route>
                <Route path="/clients">
                  <Clients />
                </Route>
              </Layout>
            </Switch>
          </Router>
        )
      case 4 : 
        return (
          <Router>
            <Switch>
              <Layout>
                <Route exact path="/">
                  <ManagerDashboard />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/commerce">
                  <CommerceTracking />
                </Route>
                <Route path="/commerce-details">
                  <CommerceTrackingDetails />
                </Route>
                <Route path="/commercial-details">
                  <CommerceTrackingDetailsCommercial />
                </Route>
                <Route path="/statistics">
                  <Statistics />
                </Route>
                <Route path="/clients">
                  <Clients />
                </Route>
              </Layout>
            </Switch>
          </Router>
        )
      case 5 :
        return publicRoutes();
    }
  }
  

  return dispatch();
}

function RootNavigator() {
  const checkLogin = IsAuthenticated();
  const position = checkLogin[1];

  return (
    checkLogin[0] ? privateRoutes(position) : publicRoutes()
  );
}

export default RootNavigator;
