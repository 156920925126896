import React, { useState } from "react"
import { axiosClient, Alerts } from "../../../../helpers";
import { withTranslation } from "react-i18next";

import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardSubtitle,
  Container,
} from "reactstrap"
import Dropzone from "react-dropzone"

// Breadcrumb
import Breadcrumbs from "../../../../components/common/Breadcrumb";

import { Link } from "react-router-dom"

const UploadStocks = (props) => {
  const [selectedFiles, setselectedFiles] = useState<any>()
  const [loader, setLoader] = useState(false)
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertType, setDisplayAlertType] = useState('');
  const [alertText, setAlertText] = useState('');

  function submitUpload(file) {
    var bodyFormData = new FormData();
    bodyFormData.append('situation', file[0]);
    setLoader(true);

    axiosClient.post('/ex/inventories', bodyFormData)
    .then(() => {
      setLoader(false);
      setDisplayAlertType('success');
      setAlertText(props.t('inventory updated !'));
      setDisplayAlert(true);
    })
    .catch(() => {
      setDisplayAlertType('error');
      setAlertText(props.t('An error occured, the uploaded file needs to be in csv format and directly extracted from AAW'));
      setDisplayAlert(true);
      setLoader(false);
    })
  }

  function renderAlert() {
    if (displayAlert) {
      return Alerts(displayAlertType, alertText);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Live inventory Yesss")} breadcrumbItem={props.t("IMPORT OBO INVENTORY - YESSS")} />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    {props.t("For the inventory file to be processed, it has to be ")} 
                    <span className="text-danger">
                      <b> {props.t("in the format extraced from AAW, in .csv, without modifications.")}</b>
                    </span>
                    {props.t(" Once processed, products and quantities will automaticaly be updated for Yesss.")}
                  </CardSubtitle>

                  <Form>
                    {renderAlert()}
                    <Dropzone
                      accept={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                      onDrop={(acceptedFiles) => { submitUpload(acceptedFiles) }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />

                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>

                            <h4>
                              { loader ? 
                                <div className="text-primary">
                                  {props.t("Loading")} <p>({props.t("may take some time")})</p> <br/>
                                  <div className="spinner-border text-primary mx-auto" role="status" />
                                </div> :
                                props.t("Click and drag the file here, or click to upload it.")
                              }
                            </h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles ? selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>

                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>

                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      }) : null}
                    </div>
                  </Form>

                  {/* <div className="text-center mt-4">
                    <button onClick={(e) => submitUpload(e)}
                      type="button"
                      className="btn btn-outline-success btn- waves-effect waves-light"
                    >
                      {props.t("Send file and update inventory")}
                    </button>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(UploadStocks);
