import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Alerts, axiosClient } from "../../../../helpers";
import { UserCachedData, Capitalize } from "../../../../hooks";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import Breadcrumb from "../../../../components/common/Breadcrumb";

function Profile(props) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // ALERT 
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertType, setDisplayAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  // DARKMODE
  const [darkmode, setDarkmode] = useState(false);

  useEffect(() => {
    if (document.body.hasAttribute('data-sidebar')) {
      const sidebar = document.body.getAttribute('data-sidebar');
      setDarkmode((sidebar === 'dark'))
    }
  }, [setDarkmode])

  function submitForm(e) {
    e.preventDefault();
    setLoading(true);

    axiosClient.patch('/ie/password', {
      password: password,
      password_confirmation: password
    })
    .then(() => {
      setDisplayAlertType('success');
      setAlertText(props.t('Password updated !'))
      setDisplayAlert(true);
      setLoading(false);
    })
    .catch(() => {
      setDisplayAlertType('error');
      setAlertText(props.t('An error occured, does the new password have 8 characters minimum ?'))
      setDisplayAlert(true);
      setLoading(false);
    })
  }

  function renderButton() {
    const spin = <div className="spinner-border text-primary mx-auto" role="status" />
    const btn = <button className="btn btn-danger btn-block waves-effect waves-light"
                  type="submit"
                >
                  {props.t('Update password')}
                </button>
    return loading ? spin : btn;
  }

  function renderAlert() {
    if (displayAlert) {
      return Alerts(displayAlertType, alertText);
    }
  }

  function toggleDarkMode() {
    if (darkmode === true) {
      localStorage.setItem('Darkmode', "false");
      document.body.dataset.sidebar = 'light';
      setDarkmode(false);
    } else {
      localStorage.setItem('Darkmode', "true");
      document.body.dataset.sidebar = 'dark';
      setDarkmode(true);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Préférences" breadcrumbItem="Profil" />
          
          <Row>
            <Col lg="12"> 
              <Card>
                <CardBody>
                  <div className="media">
                    <div className="ms-3">
                      <div className="avatar-xs avatar-md rounded-circle img-thumbnail">
                        <span className="avatar-title rounded-circle ">
                          {Capitalize(UserCachedData('firstname'))}
                        </span>
                      </div>
                    </div>

                    <Container className="align-self-center">
                      <div className="text-muted">
                        <h5>{UserCachedData('firstname')} {UserCachedData('lastname')}</h5>
                        <p className="mb-1">{UserCachedData('email')}</p>
                      </div>
                    </Container>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{props.t("Dark / Light Mode")}</h4>

          <Card>
            <CardBody>
              <div className="form-check form-switch form-switch-lg mb-3">
                <input type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  checked={darkmode}
                  onChange={() => toggleDarkMode()}  
                />

                <label className="form-check-label" htmlFor="customSwitchsizelg">
                  {props.t('Dark / Light Mode')}
                </label>
              </div>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">{props.t("Change password")}</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={submitForm}
              >
                {renderAlert()}
                <FormGroup row>
                  <Label
                    htmlFor="password"
                    className="col-form-label col-lg-2"
                  >
                    {props.t("New password")}
                  </Label>

                  <Col lg="10">
                    <Input
                      id="password"
                      name="password"
                      label={props.t("New password")}
                      value={password}
                      className="form-control"
                      placeholder={props.t("8 characters minimum")}
                      type="password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Col>
                </FormGroup>                  

                <br />

                <Row className="text-center">
                  <Col lg="10">
                    {renderButton()}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Profile);
