import ApexCharts from "apexcharts";

export default function ApexBarLine(global_benefit, current_revenue, generated_revenue_year_ago) {
  let seriesText = [];
  let date = [];
  let yAxisText = "";

  switch (localStorage.getItem("I18N_LANGUAGE")) {
    case 'fr':
      date = [
        'Janvier', 'Fevrier', 'Mars', 'Avril',
        'Mai', 'Juin', 'Juillet', 'Août',
        'Septembre', 'Octobre', 'Novembre', 'Decembre'
      ];
      seriesText = ['CA réalisé', 'VM réalisé', "CA de l'année dernière"];
      yAxisText = 'Montant €';
      break;
    case 'en':
      date = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ];
      seriesText = ['Realised Sales Revenue', 'Realised Net Margin', "Sales Revenue the Year before"];
      yAxisText = 'Total €'
      break;
  }

  var options = {
    series: [
      {
        name: seriesText[0],
        type: 'column', 
        data: current_revenue
      }, {
        name: seriesText[1],
        type: 'column',
        data: global_benefit
      }, {
        name: seriesText[2],
        type: 'line',
        data: generated_revenue_year_ago
      }
    ],
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
      }
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth'
    },
    plotOptions: {
      bar: { columnWidth: '50%' }
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: date,
    markers: { size: 0 },
    xaxis: {
      type: 'category',
      hideOverlappingLabels: false,
      labels: { show: true }
    },
    yaxis: {
      title: { text: yAxisText },
      min: 0,
      labels: {
        formatter: function(val, index) {
          return Math.round(val).toLocaleString();
        }
      },
      forceNiceScale: true
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return (Math.round(y).toLocaleString() + " €").toLocaleString();
          }

          return y;
        }
      }
    }
  };

  var chart = new ApexCharts(document.getElementById('apexBarLine'), options);

  return chart.render();
}
