import React, { useState } from "react";
import { axiosClient } from '../../../../helpers';
import { Alerts } from "../../../../helpers";
import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

function Password(props) {
  const logo = require('../../../../assets/images/logo512.png').default;
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayAlertType, setDisplayAlertType] = useState('');
  const [displayAlertText, setDisplayAlertText] = useState('');

  function submitForm(e) {
    e.preventDefault();
    setLoading(true);

    let url = `/ie/password`;

    axiosClient.post(url,
      {
        email: email,
        redirect_url: '/'
      }
    )
      .then((response) => {
        setDisplayAlertType('success');
        setDisplayAlertText(props.t("An email has just been sent with instructions to reset your password"));
        setDisplayAlert(true);
        setLoading(false);
      })
      .catch((error) => {
        setDisplayAlertType('error');
        setDisplayAlertText(props.t("An error occured... Please check your email address and try again."));
        setDisplayAlert(true);
        setLoading(false);
      })
  }

  function renderAlert() {
    if (displayAlert) {
      return Alerts(displayAlertType, displayAlertText);
    }
  }

  function renderButton() {
    const spin = <div className="spinner-border text-primary mx-auto" role="status" />
    const btn = <button className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                >
                  {props.t("Receive a new password")}
                </button>
    return loading ? spin : btn;
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary text-dark">
                          {props.t("Forgot password ?")}
                        </h5>

                        <p className="text-dark">
                          {props.t("Input your email address to receive a new password")}
                        </p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-center">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={submitForm}
                    >
                      {renderAlert()}
                  
                      <FormGroup className="mb-3" row>
                        <Label
                          htmlFor="email"
                        >
                          {props.t("Email address")}
                        </Label>

                        <Col>
                          <Input
                            id="email"
                            name="email"
                            value={email}
                            className="form-control"
                            placeholder={props.t("Input your email")}
                            type="text"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Col>
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="mt-3 d-grid">
                          {renderButton()}
                        </Col>
                      </Row>

                      <div className="mt-4 text-center">
                        <Link to="/" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> {props.t("Log in")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {props.t("Kis. Created with")}{" "}
                  <i className="mdi mdi-heart text-danger" /> {props.t("by the Kisteam")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Password);
