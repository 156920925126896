import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Credentials } from "../../../../helpers";
import { UserCachedData, Capitalize } from "../../../../hooks";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { withTranslation } from "react-i18next";

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState(false);

  function logout(e) {
    e.preventDefault()
    const action = 'clear';
    localStorage.clear();
    Credentials(action, null);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="avatar-xs d-xl-inline-block">
            <span className="avatar-title rounded-circle ">
              {Capitalize(UserCachedData('firstname'))}
            </span>
          </div>
         
          <span className="d-none d-xl-inline-block ms-2 me-1">{UserCachedData('firstname')}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item" onClick={(e) => logout(e)}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Log out")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileMenu);
