import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
// import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  // useEffect(() => {
    // const pathName = window.location.pathname;
    
    // const initMenu = () => {
    //   new MetisMenu("#side-menu");
    //   var matchingMenuItem = null;
    //   const ul = document.getElementById("side-menu");
    //   const items = ul !== null ? ul.getElementsByTagName("a") : null;
    //   if (items !== null) {
    //     for (let i = 0 ; i < items.length ; ++i) {
    //       if (pathName === items[i].pathname) {
    //         var matchingMenuItem = items[i];
    //         break;
    //       }
    //     }
    //   }
    // };
    // initMenu();
  // }, []);

  useEffect(() => {
    ref!.current.recalculate();
  });

  function getNavLinkClass(path) {
    return window.location.hash.split('#')[1] === path ? 'mm-active' : '';
  }

  const isBoss = localStorage.getItem('position') === '0' ? true : false;

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            <li className={getNavLinkClass('/')}>
              <Link to="/" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Sales tracking")}</li>
            <li className={getNavLinkClass('/commerce')}>
              <Link to="/commerce" className="waves-effect">
                <i className="bx bx-euro"></i>
                <span>{props.t("Sales tracking")}</span>
              </Link>
            </li>

            <li className={getNavLinkClass('/clients')}>
              <Link to="/clients" className="waves-effect">
                <i className="bx bx-euro"></i>
                <span>{props.t("Clients sales details")}</span>
              </Link>
            </li>

            {/* <li className={getNavLinkClass('/')}>
              <Link to="/" className="waves-effect">
                <i className="bx bx-search-alt"></i>
                <span>{props.t("Recherche détaillée")}</span>
              </Link>
            </li> */}

            {/* <li className={getNavLinkClass('/statistics')}>
              <Link to="/statistics" className="waves-effect">
                <i className="bx bx-stats"></i>
                <span>{props.t("Sales rep and product's family details")}</span>
              </Link>
            </li> */}

            {isBoss ? (
              <React.Fragment>
                <li className="menu-title">{props.t("Team")}</li>

                <li className={getNavLinkClass('/team')}>
                  <Link to="/team" className=" waves-effect">
                    <i className="bx bxs-user-detail"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>

                <li className={getNavLinkClass('/team/create')}>
                  <Link to="/team/create" className=" waves-effect">
                    <i className="bx bx-task"></i>
                    <span>{props.t("Add an user")}</span>
                  </Link>
                </li>

                <li className="menu-title">{props.t("Inventory Yesss")}</li>
                <li className={getNavLinkClass('/upload-stocks')}>
                  <Link to="/upload-stocks" className=" waves-effect">
                    <i className="bx bxs-cloud-upload"></i>
                    <span>{props.t("Upload inventory")}</span>
                  </Link>
                </li>
              </React.Fragment>
            ) : null}

            <li className="menu-title">{props.t("Settings")}</li>
            <li className={getNavLinkClass('/profile')}>
              <Link to="/profile" className="waves-effect">
                <i className="bx bx-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withTranslation()(SidebarContent);
