import React, { useEffect, useState } from "react";
import { ApexRadial, ApexBarLine, ReportsBloc, ProgressColor} from '../../../../helpers';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { axiosClient } from "../../../../helpers";

import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { withTranslation } from "react-i18next";

function Dashboard(props) {
  const [objectives, setObjectives] = useState<any>();
  const [loadGraph, setLoadGraph] = useState(true); 
  const [lang, setLang] = useState(undefined);
  const [bestAndWorst, setBestAndWorst] = useState(undefined);
  const [lastUpdate, setLastUpdate] = useState(undefined);
  const [benefit, setBenefit] = useState(undefined);
  const [currentRevenue, setCurrentRevenue] = useState(undefined);
  const [apexBarLineGlobalBenefit, setApexBarLineGlobalBenefit] = useState(undefined);
  const [apexBarLineCurrentRevenue, setApexBarLineCurrentRevenue] = useState(undefined);
  const [apexBarLineGeneratedRevenueYearAgo, setApexBarLineGeneratedRevenueYearAgo] = useState(undefined);
  
  useEffect(() => {
    setLang(localStorage.getItem("I18N_LANGUAGE"));

    const cacheObjectives = localStorage.getItem('cacheObjectives');
    const cacheBestAndWorst = localStorage.getItem('cacheBestAndWorst');
    const cacheLastUpdate = localStorage.getItem('cacheLastUpdate');
    const cacheBenefit = localStorage.getItem('cacheBenefit');
    const cacheCurrentRevenue = localStorage.getItem('cacheCurrentRevenue');
    const cacheApexBarLineGlobalBenefit = localStorage.getItem('cacheApexBarLineGlobalBenefit');
    const cacheApexBarLineCurrentRevenue = localStorage.getItem('cacheApexBarLineCurrentRevenue');
    const cacheApexBarLineGeneratedRevenueYearAgo = localStorage.getItem('cacheApexBarLineGeneratedRevenueYearAgo');

    if (cacheObjectives) { setObjectives(JSON.parse(cacheObjectives)); }
    if (cacheBestAndWorst) { setBestAndWorst(JSON.parse(cacheBestAndWorst)); }
    if (cacheLastUpdate) { setLastUpdate(JSON.parse(cacheLastUpdate)); }
    if (cacheBenefit) { setBenefit(JSON.parse(cacheBenefit)); }
    if (cacheCurrentRevenue) { setCurrentRevenue(JSON.parse(cacheCurrentRevenue)); }
    if (cacheApexBarLineGlobalBenefit) { setApexBarLineGlobalBenefit(JSON.parse(cacheApexBarLineGlobalBenefit)); }
    if (cacheApexBarLineCurrentRevenue) { setApexBarLineCurrentRevenue(JSON.parse(cacheApexBarLineCurrentRevenue)); }
    if (cacheApexBarLineGeneratedRevenueYearAgo) { setApexBarLineGeneratedRevenueYearAgo(JSON.parse(cacheApexBarLineGeneratedRevenueYearAgo)); }

    axiosClient.get('/ie/activity_infos')
      .then((response) => {
        const ABLGlobalBenefit = [].concat(response.data.activity_infos).map((activityData) => {
          return parseInt(activityData.global_benefit);
        });
        setApexBarLineGlobalBenefit(ABLGlobalBenefit);
        localStorage.removeItem('cacheApexBarLineGlobalBenefit');
        localStorage.setItem('cacheApexBarLineGlobalBenefit', JSON.stringify(ABLGlobalBenefit));

        const ABLCurrentRevenue = [].concat(response.data.activity_infos).map((activityData) => {
          return parseInt(activityData.global_revenue);
        });
        setApexBarLineCurrentRevenue(ABLCurrentRevenue);
        localStorage.removeItem('cacheApexBarLineCurrentRevenue');
        localStorage.setItem('cacheApexBarLineCurrentRevenue', JSON.stringify(ABLCurrentRevenue));

        const ABLGeneratedRevenueYearAgo = [].concat(response.data.activity_infos).map((activityData) => {
          return parseInt(activityData.global_revenue_one_year);
        });
        setApexBarLineGeneratedRevenueYearAgo(ABLGeneratedRevenueYearAgo);
        localStorage.removeItem('cacheApexBarLineGeneratedRevenueYearAgo');
        localStorage.setItem('cacheApexBarLineGeneratedRevenueYearAgo', JSON.stringify(ABLGeneratedRevenueYearAgo));

        setBestAndWorst(response.data.best_and_worst);
        localStorage.removeItem('cacheBestAndWorst');
        localStorage.setItem('cacheBestAndWorst', JSON.stringify(response.data.best_and_worst));

        setLastUpdate(response.data.last_update);
        localStorage.removeItem('cacheLastUpdate');
        localStorage.setItem('cacheLastUpdate', JSON.stringify(response.data.last_update));

        setBenefit(response.data.benefit);
        localStorage.removeItem('cacheBenefit');
        localStorage.setItem('cacheBenefit', JSON.stringify(response.data.benefit));

        setCurrentRevenue(response.data.current_revenue);
        localStorage.removeItem('cacheCurrentRevenue');
        localStorage.setItem('cacheCurrentRevenue', JSON.stringify(response.data.current_revenue));
      });

    axiosClient.get('/ie/objectives', { params: { front_commercial: true } })
      .then((response) => {
        setObjectives(response.data);
        console.log(response.data)
        localStorage.setItem('cacheObjectives', JSON.stringify(response.data));
      });
  }, [setApexBarLineGeneratedRevenueYearAgo])

  const reports = [
    {
      title: props.t("Best performance / Sales rep objectives"),
      iconClass: "bx-trophy",
      name: bestAndWorst ? bestAndWorst['best'] : '-'
    },
    {
      title: props.t("Worst performance / Sales rep objectives"),
      iconClass: "bx-error-circle",
      name: bestAndWorst ? bestAndWorst['worst'] : '-',
      danger: true
    },
    {
      title: props.t("Last updated at"),
      iconClass: "bx-time-five",
      date: lastUpdate ? lastUpdate : '-'
    }
  ];

  function loadGraphs() {
    if (apexBarLineGlobalBenefit && apexBarLineCurrentRevenue && apexBarLineGeneratedRevenueYearAgo) { 
      if (document.getElementById('apexBarLine') && document.getElementById('apexBarLine').innerHTML === "") {
        ApexBarLine(apexBarLineGlobalBenefit, apexBarLineCurrentRevenue, apexBarLineGeneratedRevenueYearAgo);
      }
    }

    if (objectives && loadGraph && currentRevenue && benefit) {
      setLoadGraph(false);

      if (document.getElementById('apexChart1') && !document.getElementById('apexcharts-radialbarTrack-0')) {
        ApexRadial({
          height: 200,
          id: 'apexChart1',
          percent: Math.round((currentRevenue * 100) / objectives.objective_ca_since_beginning_of_year)
        });
  
        ApexRadial({
          height: 200,
          id: 'apexChart2',
          percent: Math.round((benefit * 100) / objectives.objective_vm_since_beginning_of_year)
        });
      }
    }
  }

  return (
    <React.Fragment>
      {loadGraphs()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Homepage")}
            breadcrumbItem={props.t("DASHBOARD")}
          />

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Sales revenue at end of")} {lastUpdate ? new Date(lastUpdate).toLocaleString(lang, { month: 'long', year: 'numeric' }) : ""}
                  </CardTitle>
                  
                  <Row>
                    <Col sm="6">
                      <p className="text-muted">
                      {props.t("Cumulatively on")} {new Date(Date.now()).toLocaleString(lang, { year: 'numeric' })}</p>

                      <h3 className={"text-" + ProgressColor((currentRevenue && objectives)? Math.round((currentRevenue * 100) / objectives.objective_ca_since_beginning_of_year) : null)}>
                        {(currentRevenue && objectives) ? Math.round(currentRevenue).toLocaleString() : '-'} €
                      </h3>

                      <p className="text-muted">
                        <span className=" me-2">
                        {props.t("On")} {(lastUpdate && objectives) ? Math.round(objectives.objective_ca_since_beginning_of_year).toLocaleString() : '-'} {props.t("€")} 
                        {props.t(" of objectives at end of")} {lastUpdate ? new Date(lastUpdate).toLocaleString(lang, { month: 'long', year: 'numeric' }) : ""}
                        </span>
                      </p>
                    </Col>

                    <Col sm="6">
                      <div className="mt-4 mt-sm-0">
                        <div id="apexChart1" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card> 

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Realised net margin at end of")} {lastUpdate ? new Date(lastUpdate).toLocaleString(lang, { month: 'long', year: 'numeric' }) : ""}
                  </CardTitle>

                  <Row>
                    <Col sm="6">
                      <p className="text-muted">
                        {props.t("Cumulatively on")} {new Date(Date.now()).toLocaleString(lang, { year: 'numeric' })}
                      </p>

                      <h3 className={"text-" + ProgressColor((benefit && objectives) ? Math.round((benefit * 100) / objectives.objective_vm_since_beginning_of_year) : null)}>
                        {(benefit && objectives) ? Math.round(benefit).toLocaleString() : '-'}  €
                      </h3>

                      <p className="text-muted">
                        <span className=" me-2">
                          {props.t("On")} {objectives ? Math.round(objectives.objective_vm_since_beginning_of_year).toLocaleString() : '-'} {props.t("€")} 
                          {props.t(" of objectives at end of")} {lastUpdate ? new Date(lastUpdate).toLocaleString(lang, { month: 'long', year: 'numeric' }) : ""}
                        </span>
                      </p>
                    </Col>

                    <Col sm="6">
                      <div className="mt-4 mt-sm-0">
                        <div id="apexChart2" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>       
            </Col>
            
            <Col xl="8">
              <Row>
                {ReportsBloc(reports)}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      {props.t("Sales activity")}
                    </h4>
                  </div>

                  <div id="apexBarLine" />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="4">
              {/* <TopCities /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Dashboard);
