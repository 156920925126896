import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { axiosClient } from "../../../../helpers";
import { 
  ApexBarPositiveNegative,
  ApexBarLineHorizontal
} from '../../../../helpers';

import {
  Col,
  Container,
  Row
} from "reactstrap";

import Breadcrumbs from "../../../../components/common/Breadcrumb";

function Statistics(props) {
  const [loader, setLoader] = useState(true);
  const [activities, setActivities] = useState<any>();
  const [objectives, setObjectives] = useState<any>();
  const [internals, setInternals] = useState<any>();

  useEffect(() => {
    axiosClient.get('/ie/activity_infos')
      .then((response) => {
        setActivities(response.data);
      })
      setLoader(false);
  }, [setActivities])

  useEffect(() => {
    axiosClient.get('/ie/objectives')
      .then((response) => {
        setObjectives(response.data)
      })
      setLoader(false);
  }, [setObjectives])

  useEffect(() => {
    axiosClient.get('/ie/internals')
      .then((response) => {
        setInternals(response.data)
      })
      setLoader(false);
  }, [setInternals])
  
  function loadGraph() {
    if (internals &&
      document.getElementById('apexBarPositiveNegative') &&
      document.getElementById('apexBarPositiveNegative').innerHTML === ""
    ) {
      ApexBarPositiveNegative(internals);
    }
  }

  function loadGrah2() {
    if (activities &&
      document.getElementById('apexBarHorizontal') &&
      document.getElementById('apexBarHorizontal').innerHTML === "") { 
        const activitiesFilter = activities['item_family'][0].reduce(function (r, a) {
          r[a.item_family] = r[a.item_family] || [];
          r[a.item_family].push(a);

          return r;
        }, []);

        let activityData = [];

        activitiesFilter.map((af) => {
          let sumfing = af.reduce((a, b) => {
            return {
              'item_family': a['item_family'],
              'current_revenue': a['current_revenue'] + b['current_revenue'],
              'current_vm': a['current_vm'] + b['current_vm']
            }
          })

          return activityData.push(sumfing);
        })

        ApexBarLineHorizontal(activityData, objectives); 
    }
  }


  return (
    <React.Fragment>
      {loadGraph()}
      {loadGrah2()}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Sales rep team")} breadcrumbItem={props.t("Sales revenue details & net margin by sales rep")} />

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/* <div id="apexBarPositiveNegative" /> */}
                </div>
              </div>
            </Col>
          </Row>

          <br/>

          <Row className="mt-5">
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <div id="apexBarHorizontal" />
                </div>
              </div>
            </Col>
          </Row>

          {loader ? 
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t('Loading')}
                  </Link>
                </div>
              </Col>
            </Row> 
          : null}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Statistics);
