import React, { useEffect } from 'react';
import {axiosClient} from '../../helpers';

export default function IsAuthenticated() {
  const [isLoggedIn, setLoggedIn] = React.useState([false, null]);

  useEffect(()=>{
    axiosClient.get('/ie/validate_token')
    .then((response) => {
      const position = response.data.position;
      setLoggedIn([true, position]);
    })
    .catch(() => {
      setLoggedIn([false, null])
    })
  }, [])
  
  const checkLogin = isLoggedIn
  return checkLogin;
}
