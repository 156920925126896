import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { axiosClient } from "../../../../../helpers";

import {
  Col,
  Container,
  Row,
  Table
} from "reactstrap";

import Breadcrumbs from "../../../../../components/common/Breadcrumb";

function CommerceTrackingDetails(props) {
  const [loader, setLoader] = useState(false);
  const [internals, setInternals] = useState(undefined);
  const location = useLocation();
  const [lang, setLang] = useState(undefined);

  useEffect(() => {
    setLang(localStorage.getItem("I18N_LANGUAGE"));
    const cacheInternalByChief = localStorage.getItem('cacheInternalByChief' + location['state']['chief_id']); 

    setLoader(true);
    if (cacheInternalByChief) {
      setInternals(JSON.parse(cacheInternalByChief));
      setLoader(false)
    };

    axiosClient.get('/ie/internals', {
      params: { chief_id: location['state']['chief_id'] }
    })
    .then((response) => {
      setInternals(response.data);
      setLoader(false);
      localStorage.setItem('cacheInternalByChief' + location['state']['chief_id'], JSON.stringify(response.data));
    })
  }, [location]);

  function renderTableHeader() {
    var month = undefined; 

    if (internals !== undefined) {
      month = new Date(internals.activity_date).toLocaleString(lang, { month: 'long', year: 'numeric' }).toString();
    }

    return (
      <tr>
        <th scope="col">{props.t("Sales rep")}</th>
        <th scope="col">{ month !== undefined ? props.t("Cumulated sales revenue end of ") + month : props.t("Sales revenue to date")}</th>
        <th scope="col">{ month !== undefined ? props.t("Cumulated net margin end of ") + month : props.t("Net margin to date")}</th>
        <th scope="col">{props.t("Counties")}</th>
        <th scope="col" className="text-center">{props.t("Details")}</th>
      </tr>
    );
  }

  function renderTableContent() {
    let internal_data = undefined;

    if (internals) {
      internal_data = internals.internals_details;
    }

    if (internal_data !== undefined && internal_data.length > 0) {
      const renderData =  internal_data.map((data, index) => {
      // .sort((a, b) => a.activity - b.activity)

        var status;
        if (Math.round((data.activity_ca_cumule / data.objectif_ca_cumule) * 100) < 50) {
          status = "badge badge-pill bg-danger";
        } else if (Math.round((data.activity_ca_cumule / data.objectif_ca_cumule) * 100) < 90) {
          status = "badge badge-pill bg-warning";
        } else if (Math.round((data.activity_ca_cumule / data.objectif_ca_cumule) * 100) >= 90) {
          status = "badge badge-pill bg-success";
        }

        return (
          <tr key={index}>
            <td>{data.first_name} {data.last_name}</td>

            <td>
              {Math.round(data.activity_ca_cumule).toLocaleString()} €

              <br/>

              <span className={status}>
                {Math.round((data.activity_ca_cumule / data.objectif_ca_cumule) * 100)} %
              </span>
            </td>

            <td>
              {Math.round(data.activity_vm_cumule).toLocaleString()} €

              <br/>

              <span className={status}>
                {Math.round((data.activity_vm_cumule / data.objectif_vm_cumule) * 100)} %
              </span>
            </td>

            <td>
              {data.department ? data.department.replace(/,/g, ' - ') : null}
            </td>

            <td className="text-center">
              <Link to={{ 
                pathname: '/commercial-details',
                state: { 
                  current_internal: data,
                  last_update_date: internals.activity_date
                }
              }}
                className="btn btn-outline-info"
              >
                {props.t("Display details")}
              </Link>
            </td>
          </tr>
        );
      })
      
      return renderData;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Sales rep team")}
            breadcrumbItem={props.t("Team of ") + ((location['state'] !== undefined) ? location['state']['chief_name'] : '-')}
            breadcrumbLink="/commerce"
          />

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      {renderTableHeader()}
                    </thead>

                    <tbody>
                      {renderTableContent()}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {loader ? 
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </Col>
            </Row> 
          : null}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(CommerceTrackingDetails);
