import ApexCharts from "apexcharts";
import "./apex.scss"

export default function ApexRadial(data) {
  function color() {
    if (data.percent >= 90) {
      return '#34c38f';
    } else if (data.percent >= 50 ) {
      return '#feb01a';
    } else {
      return '#ff4560';
    }
  }

  var options = {
    series: [data.percent],
      chart: {
      height: data.height,
      type: 'radialBar',
      offsetY: -10
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '13px',
            color: undefined,
            offsetY: 60
          },
          value: {
            offsetY: 22,
            fontSize: '16px',
            color: undefined,
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      }
    },
    colors: [color()],
    stroke: {
      dashArray: 4
    },
    labels: ['Objectifs'],
  };

  var chart = new ApexCharts(document.getElementById(data.id), options)
  return chart.render()

}
