export default function filterMonth() {
  const d = new Date();
  const current_month = d.getMonth();

  const select = {
    1: ["ca_jan", "vm_jan"],
    2: ["ca_feb", "vm_feb"],
    3: ["ca_mar", "vm_mar"],
    4: ["ca_apr", "vm_apr"],
    5: ["ca_may", "vm_may"],
    6: ["ca_jun", "vm_jun"],
    7: ["ca_jul", "vm_jul"],
    8: ["ca_aug", "vm_aug"],
    9: ["ca_sep", "vm_sep"],
    10: ["ca_oct", "vm_oct"],
    11: ["ca_nov", "vm_nov"],
    12: ["ca_dec", "vm_dec"]
  }
  return select[current_month];
}